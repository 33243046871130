<template>
    <frontoffice-layout title="Conditions générales d'un site web">
        <div class="shell">
            <article class="pb-3">
                Les présentes conditions générales régissent l’utilisation de ce site carfulldriver.com.
                Ce site appartient et est géré par Carfull Driver SASU
                En utilisant ce site, vous indiquez que vous avez lu et compris les conditions d’utilisation et que vous acceptez de les respecter en tout temps.

                Type de site : e-commerce
            </article>

            <article class="py-3">
                <v-heading level="h4"> Propriété intellectuelle </v-heading>
                Tout contenu publié et mis à disposition sur ce site est la propriété de Carfull Driver SASU et de ses créateurs. Cela comprend, mais n’est pas limité aux images, textes, logos, documents, fichiers téléchargeables et tout ce qui contribue à la composition de ce site.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Comptes </v-heading>
                Lorsque vous créez un compte sur notre site, vous acceptez ce qui suit :

                <ol class="list-decimal pl-4">
                    <li>que vous êtes seul responsable de votre compte et de la sécurité et la confidentialité de votre compte, y compris les mots de passe ou les renseignements de nature délicate joints à ce compte, et </li>
                    <li> que tous les renseignements personnels que vous nous fournissez par l’entremise de votre compte sont à jour, exacts et véridiques et que vous mettrez à jour vos renseignements personnels s’ils changent. </li>
                </ol>

                Nous nous réservons le droit de suspendre ou de résilier votre compte si vous utilisez notre site illégalement ou si vous violez les conditions d’utilisation acceptable.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Ventes des biens et services </v-heading>
                Ce document régit la vente des services mis à disposition sur notre site.

                Les services que nous offrons comprennent :

                <ul class="list-disc pl-4">
                    <li>transport</li>
                </ul>

                Les services liés à ce document sont les services qui sont affichés sur notre site au moment où vous y accédez. Toutes les informations, descriptions ou images que nous fournissons sur nos services sont décrites et présentées avec la plus grande précision possible. Cependant, nous ne sommes pas légalement  tenus par ces informations, descriptions ou images car nous ne pouvons pas garantir l’exactitude de chaque produit ou service que nous fournissons. Vous acceptez d’acheter ces services à vos propres risques.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Paiements </v-heading>
                Nous acceptons les modes de paiement suivants sur ce site :
                Lorsque vous nous fournissez vos renseignements de paiement, vous nous confirmez que vous avez autorisé l’utilisation et l’accès à l’instrument de paiement que vous avez choisi d’utiliser. En nous fournissant vos détails de paiement, vous confirmez que vous nous autorisez à facturer le montant dû à cet instrument de paiement.
                Si nous estimons que votre paiement a violé une loi ou l’une de nos conditions d’utilisation, nous nous réservons le droit d’annuler votre transaction.

                <em>Services</em>
                Les services seront facturés en totalité une fois le service terminé.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Limitation de responsabilité </v-heading>
                Carfull Driver SASU ou l’un de ses employés sera tenu responsable de tout problème découlant de ce site. Néanmoins, Carfull Driver SASU et ses employés ne seront pas tenus responsables de tout problème découlant de toute utilisation irrégulière de ce site.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Indemnité </v-heading>
                En tant qu’utilisateur, vous indemnisez par les présentes Carfull Driver SASU de toute responsabilité, de tout coût, de toute cause d’action, de tout dommage ou de toute dépense découlant de votre utilisation de ce site ou de votre violation de l’une des dispositions énoncées dans le présent documen
            </article>

            <article class="py-3">
                <v-heading level="h4"> Lois applicables </v-heading>
                Ce document est soumis aux lois applicables en France et vise à se conformer à ses règles et règlements nécessaires. Cela inclut la réglementation à l’échelle de l’UE énoncée dans le RGPD.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Divisibilité </v-heading>
                Si, à tout moment, l’une des dispositions énoncées dans le présent document est jugée incompatible ou invalide en vertu des lois applicables, ces dispositions seront considérées comme nulles et seront retirées du présent document. Toutes les autres dispositions ne seront pas touchées par les lois et le reste du document sera toujours considéré comme valide.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Modifications </v-heading>
                Ces conditions générales peuvent être modifiées de temps à autre afin de maintenir le respect de la loi et de refléter tout changement à la façon dont nous gérons notre site et la façon dont nous nous attendons à ce que les utilisateurs se comportent sur notre site. Nous recommandons à nos utilisateurs de vérifier ces conditions générales de temps à autre pour s’assurer qu’ils sont informés de toute mise à jour. Au besoin, nous informerons les utilisateurs par courriel des changements apportés à ces conditions ou nous afficherons un avis sur notre site.
            </article>

            <article class="py-3">
                <v-heading level="h4"> Contact </v-heading>
                Veuillez communiquer avec nous si vous avez des questions ou des préoccupations. Nos coordonnées sont les suivantes :
                <ul class="list-disc pl-4">
                    <li>07 54 01 24 10</li>
                    <li>carfulldriver@gmail.com</li>
                </ul>
            </article>
        </div>
    </frontoffice-layout>
</template>